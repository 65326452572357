<template>
<div>
  <div class="background-image"></div>
  <div class="logout-button">
    <a-button icon="poweroff" type="danger" size="large" shape="round" @click="logout">Logout</a-button>
  </div>
  <div class="container-sm container-md">
    <div class="row">
      <div class="col-sm-6 col-md-3 col-lg-2" v-for="worksheet in worksheets" :key="worksheet.id">
        <div class="card text-center">
          <div class="card-header">
            <strong>{{worksheet.Week}}</strong>
          </div>
          <div class="card-body">
            <!-- <p class="mb-5">{{worksheet.Description}}</p> -->
            <p class="mb-5"></p>
            <a :href="worksheet.WorksheetPath" target="_blank">
              <a-button id="pdf-button" class="pdf-button" type="dashed" icon="file-pdf" shape="round">Go to</a-button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import _ from 'lodash';
import studentService from '@/services/student';
import tools from '@/tools/cookie';

export default {
  data(){
    return {
      worksheets: [],
    }
  },
  created(){
    this.getWorksheets();
  },
  methods: {
    async getWorksheets() {
      const result = await studentService.getStudentWorksheets();
      this.worksheets = _.orderBy(result, ['UnlockWeekID'], ['asc']);
      this.worksheets = this.worksheets.filter(x => x.Status === 1);
    },
    logout() {
      this.$store.commit('setAuthenticationRole', '');
      tools.cookie.set('studentToken', '', 0);
      this.$router.push({name : 'StudentLogin'})
    }
  }
}
</script>
<style scoped>
.card {
  width: 100%;
  height: 200px;
  margin-top: 10px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}
.card:hover {
  box-shadow: 0 3px 7px rgba(0,0,0,0.25);
  transform: translateY(-5px);
}
.card:last-child{
  margin-bottom: 20px;
}
.card:first-child{
  margin-top: 30px;
}
.logout-button {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 1000;
}
.pdf-button:hover {
  color: indianred;
  background-color: #fff;
  border-color: indianred;
}
.pdf-button:active {
  color: indianred;
  background-color: #fff;
  border-color: indianred;
}
.background-image {
  background-attachment: fixed;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #3b3b3b;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='250' height='250' viewBox='0 0 20 20'%3E%3Cg %3E%3Cpolygon fill='%23272727' points='20 10 10 0 0 0 20 20'/%3E%3Cpolygon fill='%23272727' points='0 10 0 20 10 20'/%3E%3C/g%3E%3C/svg%3E");
}
.card-body {
  background: #f5f5f5;
}
</style>