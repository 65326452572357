import api from './base';

export default{
  async createStudent(payload) {
    const result = await api().post('/api/Student', payload);
    return result.data;
  },
  async getStudents(payload) {
    const result = await api().get('/api/Student/GetByBranchID/' + payload);
    return result.data;
  },
  async deleteStudent(payload) {
    const result = await api().delete('/api/Student/' + payload);
    return result.data;
  },
  async getInfoByCode(payload) {
    const result = await api().post('/api/Student/getInfoByLoginCode', payload);
    return result.data;
  },
  async getStudentWorksheets() {
    const result = await api().get('/api/Student/getStudentWorksheets');
    return result.data;
  }
}